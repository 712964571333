<template>
  <div
    class="tw-overflow-hidden tw-animate-pulse tw-bg-primary-600 tw-rounded"
    :class="sizeClasses"
    :style="containerStyle"
  ></div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    sizeClasses?: string;
    borderRadius?: string;
  }>(),
  {
    sizeClasses: 'tw-w-full tw-h-4',
  },
);

const containerStyle = computed(() => {
  return { borderRadius: props.borderRadius };
});
</script>
